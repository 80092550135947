import React, { useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize, useIntersectionObserver } from "@hooks";
import StudyCard from "@components/StudyCard";

const breakSize = 950;

interface IResponse {
  id: number;
  media: {
    publicURL: string;
  };
  text: string;
}

interface StudyResponsesProps {
  responses: Array<IResponse>;
  iconColors: readonly [string, string];
}

function StudyResponses({ responses, iconColors }: StudyResponsesProps) {
  const { width } = useWindowSize();
  if (width === undefined) return null;

  const threeResponses = (
    <>
      <StudyCardIntersectionObserver top={25} left={25}>
        <StudyCard response={responses[0]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>

      <StudyCardIntersectionObserver top={25} left={75}>
        <StudyCard response={responses[1]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>

      <StudyCardIntersectionObserver top={75} left={50}>
        <StudyCard response={responses[2]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>
    </>
  );

  const fourResponses = (
    <>
      <StudyCardIntersectionObserver top={75} left={25}>
        <StudyCard response={responses[0]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>

      <StudyCardIntersectionObserver top={20} left={75}>
        <StudyCard response={responses[1]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>

      <StudyCardIntersectionObserver top={75} left={75}>
        <StudyCard response={responses[2]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>

      <StudyCardIntersectionObserver top={20} left={25}>
        <StudyCard response={responses[3]} iconColors={iconColors} />
      </StudyCardIntersectionObserver>
    </>
  );

  if (responses.length === 3) return threeResponses;
  if (responses.length === 4) return fourResponses;

  return null;
}

export default StudyResponses;

interface StudyCardIOProps {
  top: number;
  left: number;
  children: React.ReactNode;
}

function StudyCardIntersectionObserver({
  top,
  left,
  children
}: StudyCardIOProps) {
  const intersectionRef = useRef(null);
  const [isVisible] = useIntersectionObserver({
    elementRef: intersectionRef,
    threshold: 0.25,
    freezeOnceVisible: true
  });

  const { width } = useWindowSize();
  if (width === undefined) return null;

  return (
    <StudyCardContainer
      ref={intersectionRef}
      top={top}
      left={left}
      initial={{
        x: width > breakSize ? "-50%" : "0%",
        y: width > breakSize ? "-50%" : "0%"
      }}
    >
      <AnimatePresence>{isVisible && children}</AnimatePresence>
    </StudyCardContainer>
  );
}

const StudyCardContainer = styled(motion.div)<{
  top?: number;
  left?: number;
}>`
  position: absolute;
  top: ${(p) => p.top ?? 75}%;
  left: ${(p) => p.left ?? 25}%;
  width: 235px;
  height: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (max-width: 950px) {
    position: static;
  }
`;
