import React from "react";
import { motion } from "framer-motion";

interface StudyGraphBackgroundProps {
  numberOfResponses: number;
}

function StudyGraphBackground({
  numberOfResponses
}: StudyGraphBackgroundProps) {
  const threeResponses = (
    <svg viewBox="0 0 450 502" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <g opacity="0.5">
          <motion.path
            // {...secondaryTransition}
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M415.431 282.463C402.113 188.692 320.635 118 225.842 118C196.191 118 167.693 124.612 141.76 137.354C146.56 136.576 151.686 136.111 157.068 136.111C163.509 136.111 169.927 136.798 176.147 138.149C210.473 145.608 228.318 177.707 229.464 179.832L338.841 369.44C347.307 369.21 374.77 366.866 392.981 346.799C417.669 319.598 415.565 284.338 415.431 282.463Z"
            fill="url(#paint0_linear)"
          />
          <motion.path
            // {...secondaryTransition}
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M174.551 146.058C168.89 144.832 163.043 144.212 157.172 144.212C129.728 144.212 108.937 157.754 107.443 158.756C60.7638 195.384 34 250.315 34 309.482C34 374.441 66.2286 433.355 118.644 468.463C112.111 460.442 106.071 450.458 102.176 438.322C91.4014 404.754 110.447 373.165 111.626 371.255L221.367 181.673C217.099 174.819 201.02 151.791 174.551 146.058Z"
            fill="url(#paint1_linear)"
          />
          <motion.path
            // {...secondaryTransition}
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M398.732 352.764C376.799 376.876 343.344 378.096 336.859 378.099C336.326 378.099 336.006 378.088 335.925 378.088H117.145C113.338 385.185 101.417 410.544 109.648 436.225C121.056 471.811 153.854 487.632 154.186 487.789L154.55 487.96C177.097 496.926 200.927 501.471 225.381 501.471C326.437 501.471 409.466 422.959 416.5 323.812C412.813 333.362 407.21 343.446 398.732 352.764Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <motion.path
          // {...mainTransition}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M448.743 193.486C433.074 83.1676 337.218 0 225.696 0C190.813 0 157.286 7.7793 126.777 22.7693C132.424 21.8547 138.454 21.3066 144.786 21.3066C152.364 21.3066 159.914 22.115 167.232 23.7044C207.615 32.4806 228.609 70.2432 229.957 72.7438L358.636 295.812C368.596 295.542 400.905 292.784 422.331 269.176C451.375 237.175 448.9 195.692 448.743 193.486Z"
          fill="url(#paint3_linear)"
        />
        <motion.path
          // {...mainTransition}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165.354 33.0096C158.694 31.5675 151.815 30.8379 144.909 30.8379C112.622 30.8379 88.1616 46.7697 86.4032 47.948C31.4868 91.0402 0 155.665 0 225.273C0 301.695 37.916 371.006 99.581 412.31C91.8948 402.873 84.789 391.127 80.2075 376.85C67.531 337.358 89.9372 300.195 91.3247 297.948L220.431 74.9098C215.41 66.8462 196.494 39.7543 165.354 33.0096Z"
          fill="url(#paint4_linear)"
        />
        <motion.path
          // {...mainTransition}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M429.096 276.192C403.292 304.559 363.933 305.995 356.304 305.999C355.678 305.999 355.301 305.985 355.205 305.985H97.8171C93.3385 314.334 79.3136 344.168 88.9968 374.382C102.419 416.248 141.004 434.861 141.395 435.045L141.823 435.247C168.349 445.795 196.385 451.142 225.154 451.142C344.043 451.142 441.724 358.774 450 242.132C445.661 253.367 439.07 265.231 429.096 276.192Z"
          fill="url(#paint5_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="141.76"
          y1="332.689"
          x2="415.529"
          y2="332.689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD" />
          <stop offset={1} stopColor="#9EBDFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="91.786"
          y1="176.605"
          x2="206.392"
          y2="441.654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC7D49" />
          <stop offset={1} stopColor="#FF788D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="368.584"
          y1="348.483"
          x2="137.634"
          y2="488.994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1A5F6" />
          <stop offset={1} stopColor="#FC89CB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="126.777"
          y1="252.576"
          x2="448.858"
          y2="252.576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD" />
          <stop offset={1} stopColor="#9EBDFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="67.984"
          y1="68.9473"
          x2="202.815"
          y2="380.769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC7D49" />
          <stop offset={1} stopColor="#FF788D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="393.628"
          y1="271.156"
          x2="121.923"
          y2="436.464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1A5F6" />
          <stop offset={1} stopColor="#FC89CB" />
        </linearGradient>
      </defs>
    </svg>
  );

  const fourResponses = (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 708 739"
    >
      <g filter="url(#filter0_d)">
        <g opacity="0.5">
          <g filter="url(#filter1_f)">
            <path
              fill="url(#oiwerj)"
              fillRule="evenodd"
              d="M258.2 348.2c35.816 0 69.682 13.755 95.363 38.731l.126.122c23.333-23.992 37.711-56.741 37.711-92.853 0-73.564-59.636-133.2-133.2-133.2S125 220.636 125 294.2c0 36.135 14.395 68.903 37.756 92.899 24.666-24.054 58.351-38.899 95.444-38.899z"
              clipRule="evenodd"
            ></path>
          </g>
          <g filter="url(#filter2_f)">
            <path
              fill="url(#sdlofkjfff)"
              fillRule="evenodd"
              d="M449 161c-36.135 0-68.903 14.395-92.9 37.756 24.054 24.667 38.9 58.351 38.9 95.444s-14.846 70.777-38.9 95.444c23.997 23.36 56.765 37.756 92.9 37.756 73.564 0 133.2-59.636 133.2-133.2S522.564 161 449 161z"
              clipRule="evenodd"
            ></path>
          </g>
          <g filter="url(#filter3_f)">
            <path
              fill="url(#paint2_linear)"
              fillRule="evenodd"
              d="M312.2 485c0-35.815 13.755-69.682 38.731-95.363l.122-.126C327.061 366.178 294.312 351.8 258.2 351.8 184.636 351.8 125 411.436 125 485s59.636 133.2 133.2 133.2c36.135 0 68.903-14.395 92.899-37.756C327.045 555.778 312.2 522.093 312.2 485z"
              clipRule="evenodd"
            ></path>
          </g>
          <g filter="url(#filter4_f)">
            <path
              fill="url(#paint3_linear)"
              fillRule="evenodd"
              d="M449 431c-35.84 0-69.725-13.771-95.411-38.777l-.078-.076C330.178 416.139 315.8 448.888 315.8 485c0 73.564 59.636 133.2 133.2 133.2S582.2 558.564 582.2 485c0-36.135-14.395-68.903-37.756-92.899C519.778 416.154 486.093 431 449 431z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <path
          fill="url(#paint4_linear)"
          fillRule="evenodd"
          d="M248 288c39.795 0 77.425 15.283 105.959 43.034l.14.136C380.025 304.512 396 268.124 396 228c0-81.738-66.262-148-148-148s-148 66.262-148 148c0 40.15 15.995 76.559 41.951 103.221C169.358 304.495 206.786 288 248 288z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint5_linear)"
          fillRule="evenodd"
          d="M308 440c0-39.795 15.283-77.425 43.034-105.959l.136-.14C324.512 307.975 288.124 292 248 292c-81.738 0-148 66.262-148 148s66.262 148 148 148c40.15 0 76.559-15.995 103.221-41.951C324.495 518.642 308 481.214 308 440z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint6_linear)"
          fillRule="evenodd"
          d="M460 80c-40.15 0-76.559 15.995-103.222 41.951C383.505 149.359 400 186.786 400 228c0 41.214-16.495 78.641-43.222 106.049C383.441 360.005 419.85 376 460 376c81.738 0 148-66.262 148-148S541.738 80 460 80z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint7_linear)"
          fillRule="evenodd"
          d="M460 380c-39.822 0-77.472-15.301-106.012-43.085l-.087-.085C327.975 363.488 312 399.876 312 440c0 81.738 66.262 148 148 148s148-66.262 148-148c0-40.15-15.995-76.559-41.951-103.221C538.642 363.505 501.214 380 460 380z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="708"
          height="738.2"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="20"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.792157 0 0 0 0 0.803922 0 0 0 0 0.843137 0 0 0 0.01 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_f"
          width="462.116"
          height="421.815"
          x="27.142"
          y="63.142"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur"
            stdDeviation="48.929"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter2_f"
          width="421.816"
          height="462.116"
          x="258.242"
          y="63.142"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur"
            stdDeviation="48.929"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter3_f"
          width="421.815"
          height="462.116"
          x="27.142"
          y="253.942"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur"
            stdDeviation="48.929"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter4_f"
          width="462.116"
          height="421.815"
          x="217.942"
          y="294.243"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur"
            stdDeviation="48.929"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="oiwerj"
          x1="273.285"
          x2="458.823"
          y1="438.853"
          y2="259.673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="1" stopColor="#91C2FF"></stop>
        </linearGradient>
        <linearGradient
          id="sdlofkjfff"
          x1="332.747"
          x2="511.927"
          y1="273.893"
          y2="459.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B3FF"></stop>
          <stop offset="1" stopColor="#D9A1F2"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="443.497"
          x2="193.996"
          y1="463.515"
          y2="265.058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF777A"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="502.77"
          x2="317.232"
          y1="338.71"
          y2="517.891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F78DD3"></stop>
          <stop offset="1" stopColor="#FF7DA9"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="264.761"
          x2="470.915"
          y1="388.726"
          y2="189.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="1" stopColor="#91C2FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="453.885"
          x2="176.662"
          y1="416.128"
          y2="195.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF777A"></stop>
          <stop offset="1" stopColor="#FC7D49"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="330.83"
          x2="529.92"
          y1="205.437"
          y2="411.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B3FF"></stop>
          <stop offset="1" stopColor="#D9A1F2"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="519.745"
          x2="313.592"
          y1="277.456"
          y2="476.545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F78DD3"></stop>
          <stop offset="1" stopColor="#FF7DA9"></stop>
        </linearGradient>
      </defs>
    </motion.svg>
  );

  return (
    <>
      {numberOfResponses === 3 && threeResponses}
      {numberOfResponses === 4 && fourResponses}
    </>
  );
}

export default StudyGraphBackground;
