import React from "react";
import styled, { keyframes, StyledComponent } from "styled-components";
import { motion } from "framer-motion";
import { Link, GatsbyLinkProps } from "gatsby";
import { ArrowNarrowRight } from "@styled-icons/heroicons-outline/ArrowNarrowRight";
import { Mail } from "@styled-icons/heroicons-solid/Mail";

type IconOption = "arrow-right" | "arrow-left" | "mail";

interface LinkButtonProps {
  to: string;
  icon?: IconOption;
  children: React.ReactNode;
}

export function GhostLinkButton({
  to,
  icon,
  children,
  ...props
}: LinkButtonProps & any) {
  return (
    <StyledGhostLink to={to} {...props}>
      {icon === "arrow-left" && (
        <ArrowIcon
          style={{
            marginLeft: 0,
            marginRight: "1rem",
            transform: "rotate(180deg)"
          }}
        />
      )}
      {children}
      {icon === "arrow-right" && <ArrowIcon />}
    </StyledGhostLink>
  );
}

// TODO: Fix types for this button
export function LinkButton({
  to,
  icon,
  children,
  ...props
}: LinkButtonProps & any) {
  return (
    <StyledLink to={to} {...props}>
      {icon === "arrow-left" && (
        <ArrowIcon style={{ marginLeft: 0, marginRight: "1rem" }} />
      )}
      {children}
      {icon === "arrow-right" && <ArrowIcon />}
    </StyledLink>
  );
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconOption;
}

export function Button({
  icon = "arrow-right",
  children,
  ...props
}: ButtonProps) {
  return (
    <StyledButton {...props}>
      {children}
      {icon === "arrow-right" && <ArrowIcon />}
      {icon === "mail" && <MailIcon />}
    </StyledButton>
  );
}

const StyledLink = styled(Link)`
  font-size: 1.8rem;
  letter-spacing: 0.25px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  background: #635ec0;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 50ms ease-in-out;

  &:hover {
    background: #4540a4;
  }
`;

const StyledGhostLink = styled(StyledLink)`
  background: transparent;
  box-shadow: none;
  color: #635ec0;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #635ec0;
`;

const StyledButton = styled.button`
  font-size: 1.8rem;
  letter-spacing: 0.25px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  background: #635ec0;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <SpinnerDot>.</SpinnerDot>
      <SpinnerDot>.</SpinnerDot>
      <SpinnerDot>.</SpinnerDot>
    </SpinnerContainer>
  );
}

const blink = keyframes`
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
`;

const SpinnerDot = styled.span`
  font-size: 1rem;
  line-height: 0;
  align-items: center;
  justify-content: center;
  animation: ${blink} 1.4s infinite linear both;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ArrowIcon = styled(ArrowNarrowRight)`
  margin-left: 1rem;
  height: 22px;
  color: inherit;
  fill: inherit;
  display: inline-block;
`;

const MailIcon = styled(Mail)`
  margin-left: 2rem;
  height: 22px;
  color: white;
  display: inline-block;
`;
