import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, Variants } from "framer-motion";
import { navigate, PageProps } from "gatsby";
import hexToRgba from "hex-to-rgba";
import PageWrapper from "@components/PageWrapper";
import SEO from "@components/SEO";
import Stack from "@components/Stack";
import StudyGraphBackground from "@components/StudyGraphBackground";
import StudyResponses from "@components/StudyResponses";
import { GhostLinkButton } from "@components/Button";
import { FacebookSquare } from "@styled-icons/boxicons-logos/FacebookSquare";
import { Snapchat } from "@styled-icons/boxicons-logos/Snapchat";

interface StudyPageProps {
  pageContext: {
    study: ICaseStudy;
    previous: ICompany | null;
    next: ICompany | null;
  };
}

interface ICompany {
  path: string;
  company: string;
  logo: string;
}

interface ICaseStudy {
  id: number;
  theme: string;
  company: string;
  title: string;
  headline: string;
  header: string;
  subheader: string;
  logo: {
    publicURL: string;
  };
  responses: Array<IResponse>;
  content_caption: string;
  content_image: {
    publicURL: string;
  };
  external_url: string;
}

interface IResponse {
  id: number;
  media: {
    publicURL: string;
  };
  text: string;
}

function getBackground(color: string) {
  const colors: { [key: string]: string } = {
    purple: "#635ec0",
    blue: "#4677D7",
    red: "#D45079",
    green: "#45E4AA"
  };

  return colors[color];
}

function getTextColorFromTheme(theme: string) {
  // Themes that should have their primary text color as black instead of white
  const blackText = ["green"];

  if (blackText.includes(theme)) {
    return "black";
  } else {
    return "#ffffff";
  }
}

const ease = [0, 0.3, 0, 0.65];

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075
    }
  }
};

const leftItem: Variants = {
  hidden: { opacity: 0, x: -15, scale: 0.95 },
  show: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      ease,
      duration: 0.15
    }
  }
};

const rightItem: Variants = {
  hidden: { opacity: 0, x: 15, scale: 0.95 },
  show: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      ease,
      duration: 0.15
    }
  }
};

function StudyPage({ pageContext, location }: StudyPageProps & PageProps) {
  const { study, previous, next } = pageContext;

  function handlePrevious() {
    if (previous !== null) navigate(previous.path);
  }

  function handleNext() {
    if (next !== null) navigate(next.path);
  }

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      const path = window.location.pathname;
      const isCaseStudyUrl = path.startsWith("/work") && path.length > 6;

      if (isCaseStudyUrl) {
        if (event.code === "Escape") {
          navigate("/work");
        }

        if (event.code === "ArrowLeft") {
          handlePrevious();
        }

        if (event.code === "ArrowRight") {
          handleNext();
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const iconColors = [
    hexToRgba(getBackground(study.theme), 0.3),
    hexToRgba(getBackground(study.theme), 0.75)
  ] as const;

  return (
    <PageWrapper>
      <SEO title={study.title} pathname={location.pathname} />

      <StudyWrapper
        animate={{
          backgroundColor: getBackground(study.theme)
        }}
        layout
      >
        <StudyOverview>
          <AnimatePresence>
            <StudyOverviewContent
              key={study.company}
              variants={container}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <StudyLogoContainer>
                <StudyLogo>
                  <img src={study.logo.publicURL} />
                </StudyLogo>
              </StudyLogoContainer>

              <StudyHeadline variants={leftItem}>
                {study.headline}
              </StudyHeadline>
              <StudyPlatforms variants={leftItem}>
                <span>Study conducted via</span>
                <StudyIconContainer>
                  <StudyFacebookIcon />
                </StudyIconContainer>
                <StudyIconContainer>
                  <StudySnapchatIcon />
                </StudyIconContainer>
              </StudyPlatforms>
              <StudyLineBreak variants={leftItem} />
              <GhostLinkButton to="/work" icon="arrow-left">
                Back to studies
              </GhostLinkButton>
            </StudyOverviewContent>
          </AnimatePresence>
        </StudyOverview>

        <AnimatePresence exitBeforeEnter>
          <StudyDetails
            key={study.company}
            variants={container}
            theme={study.theme}
            initial="hidden"
            animate="show"
            exit="hidden"
            type="column"
            gap={1}
          >
            <StudyDetailsHeadline variants={rightItem}>
              <motion.h1>{study.header}</motion.h1>
            </StudyDetailsHeadline>

            <StudyDetailsSubheader variants={rightItem}>
              {study.subheader}
            </StudyDetailsSubheader>

            {study.content_image !== null ? (
              study.external_url !== null ? (
                <StudyDetailsAnchor
                  variants={rightItem}
                  href={study.external_url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={study.content_image.publicURL}
                    alt={study.content_caption}
                  />
                  <StudyDetailsCaption>
                    {study.content_caption}
                  </StudyDetailsCaption>
                </StudyDetailsAnchor>
              ) : (
                <StudyDetailsImage variants={rightItem}>
                  <img
                    src={study.content_image.publicURL}
                    alt={study.content_caption}
                  />
                  <StudyDetailsCaption>
                    {study.content_caption}
                  </StudyDetailsCaption>
                </StudyDetailsImage>
              )
            ) : null}

            {study.responses.length > 0 && (
              <StudyDetailsContainer
                // variants={cardContainer}
                initial="hidden"
                animate="show"
              >
                <StudyResponses
                  responses={study.responses}
                  iconColors={iconColors}
                />
                <StudyGraphBackground
                  numberOfResponses={study.responses.length}
                />
              </StudyDetailsContainer>
            )}

            <StudyDetailsNavigationContainer variants={rightItem}>
              <StudyDetailsNavigation>
                {previous !== null && (
                  <StudyDetailsCompany onClick={handlePrevious}>
                    <span>Previous</span>
                    <p>{previous.company}</p>

                    {/* <img src={previous.logo} alt={previous.company} /> */}
                  </StudyDetailsCompany>
                )}
              </StudyDetailsNavigation>
              <StudyDetailsNavigation>
                {next !== null && (
                  <StudyDetailsCompany onClick={handleNext}>
                    <span>Next</span>
                    <p>{next.company}</p>
                    {/* <img src={next.logo} alt={next.company} /> */}
                  </StudyDetailsCompany>
                )}
              </StudyDetailsNavigation>
            </StudyDetailsNavigationContainer>
          </StudyDetails>
        </AnimatePresence>
      </StudyWrapper>
    </PageWrapper>
  );
}

export default StudyPage;

const StudyWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: clamp(300px, 42.5%, 700px) 1fr;
  position: relative;
  background: slateblue;
  /* touch-action: none; */
  /* cursor: none; */

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

const StudyOverview = styled(motion.div)`
  background: white;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 16rem 0;

  @media (max-width: 950px) {
    padding: 20rem 0 4rem 0;
  }
`;

const StudyOverviewContent = styled(motion.div)`
  position: sticky;
  top: 6rem;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 3rem;
  justify-items: flex-end;
  width: 100%;
  max-width: 505px;
  padding: 2rem 4rem;

  @media (max-width: 950px) {
    max-width: 75ch;
    justify-items: flex-start;
    margin: 0 auto;
  }
`;

const StudyLogoContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  justify-self: flex-end;
  width: 100%;
  height: 100%;
  max-width: 225px;
  max-height: 100px;

  @media (max-width: 950px) {
    justify-self: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const StudyLogo = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 950px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const StudyDetails = styled(Stack)<{ theme: string }>`
  max-width: 90ch;
  padding: 16rem 6rem;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: min-content;

  --text-color: ${(p) => getTextColorFromTheme(p.theme)};

  h1,
  p {
    font-family: "Open Sans", sans-serif;
  }

  h1 {
    /* font-style: italic; */
    font-weight: 200;
    margin: 1rem 0;
    color: white;
  }

  > p {
    color: white;
  }

  @media (max-width: 950px) {
    max-width: 75ch;
    padding: 12rem 4rem;
    margin: 0 auto;
  }
`;

const StudyDetailsHeadline = styled(motion.div)`
  position: relative;

  h1 {
    position: relative;
    z-index: 2;
    font-size: clamp(3.4rem, 5vw, 5.1rem);
    line-height: 1;
    letter-spacing: -1px;
    font-style: italic;

    ::after {
      content: "Key Insight";
      position: absolute;
      top: 0;
      left: 0;
      text-transform: uppercase;
      font-style: normal;
      font-size: clamp(1.25rem, 5vw, 1.45rem);
      font-weight: 400;
      letter-spacing: 1px;
      color: hsl(0 0% 90%);
      transform: translate(0.4rem, -135%);
    }
  }

  @media (max-width: 950px) {
    h1 {
      line-height: 1;
    }
  }
`;

const StudyHeadline = styled(motion.div)`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(18px, 4vw, 24px);
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.5px;
  line-height: 1.35;
  color: hsl(0 0% 25%);

  @media (max-width: 950px) {
    text-align: left;
  }
`;

const StudyLineBreak = styled(motion.div)`
  width: 100%;
  height: 5px;
  background: linear-gradient(
    90deg,
    #ff7995 -16.08%,
    #94c3ff 7.77%,
    #fd7c55 38.42%,
    #fe7b69 64.31%,
    #95c2ff 89.52%,
    #f68dd2 114.73%
  );
  border-radius: 500px;
`;

const StudyPlatforms = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 1rem;

  span {
    font-size: 1.25rem;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.75px;
    font-weight: 400;
    text-transform: uppercase;
    color: hsl(0 0% 55%);
    margin: auto 0;
  }

  @media (max-width: 950px) {
    justify-content: flex-start;
  }
`;

const StudyIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StudyFacebookIcon = styled(FacebookSquare)`
  width: 100%;
  height: 100%;
  color: #505050;
  display: block;
`;

const StudySnapchatIcon = styled(Snapchat)`
  width: 100%;
  height: 100%;
  color: #505050;
  display: block;
`;

const StudyDetailsNavigationContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14rem;
`;

const StudyDetailsNavigation = styled.div`
  text-align: left;
`;

const StudyDetailsCompany = styled.div`
  padding: 1rem 2rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  color: transparent;
  font-size: 1.7rem;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
  }

  p {
    font-size: 2rem;
    color: white;
    letter-spacing: 0.5px;
  }

  img {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: 4rem;
    opacity: 0.4;
    padding: 1rem 2rem;
  }
`;

const StudyDetailsSubheader = styled(motion.p)`
  position: relative;
`;

const StudyDetailsImage = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
`;

const StudyDetailsAnchor = styled(motion.a)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
`;

const StudyDetailsCaption = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: clamp(1.15rem, 5vw, 1.7rem);
  font-weight: 200;
  letter-spacing: 1px;
  color: white;
  width: 100%;
  text-align: center;
  transform: translate(-50%, 175%);
`;

const StudyDetailsContainer = styled(motion.div)`
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10rem;
  /* border: 1px solid blue; */

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 0;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    height: auto;
    display: grid;
    grid-gap: 2rem;
    grid-auto-flow: row;

    > svg {
      display: none;
    }
  }
`;
