import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { media } from "@styles";

interface Props {
  children: React.ReactNode;
}

function PageWrapper({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

export default PageWrapper;

const Wrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: relative;
`;
