import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { QuoteAltLeft } from "@styled-icons/boxicons-solid/QuoteAltLeft";
import { ChatSquareTextFill } from "@styled-icons/bootstrap/ChatSquareTextFill";
import { Close } from "@styled-icons/ionicons-solid/Close";

interface IResponse {
  id: number;
  media?: {
    publicURL: string;
  };
  text: string;
}

const pop = {
  open: {
    clipPath: `circle(300px at 50% 88.5%)`,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 80
    }
  },
  closed: {
    clipPath: "circle(17.5px at 50% 88.5%)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

function StudyCard({
  response,
  iconColors
}: {
  response: IResponse;
  iconColors: readonly [string, string];
}) {
  const [isClicked, setIsClicked] = useState(false);
  const [softColor, softerColor] = iconColors;

  return (
    <Component
      style={{
        background: response?.media?.publicURL ? "none" : "white",
        padding: response?.media?.publicURL ? "none" : "1.25rem 1.75rem"
      }}
      initial={{
        opacity: 0,
        scale: 0.5
      }}
      animate={{ opacity: 1, scale: 1, transition: { delay: 0.7 } }}
      exit={{ opacity: 0, scale: 0.5 }}
      onClick={(e) => {
        e.stopPropagation();
        setIsClicked((curr) => !curr);
      }}
    >
      {response?.media?.publicURL ? (
        <>
          <img src={response.media.publicURL} alt="" />
          {response.text && (
            <>
              <Pop
                variants={pop}
                initial="closed"
                animate={isClicked ? "open" : "closed"}
              >
                {isClicked && (
                  <Text
                    initial={{ opacity: 0, scale: 0.85, y: 20 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ delay: 0.15 }}
                  >
                    {response.text}
                    <QuoteIconContainer>
                      <QuoteIcon
                        style={{
                          color: softColor
                        }}
                      />
                    </QuoteIconContainer>
                  </Text>
                )}
              </Pop>

              <IconContainer>
                {isClicked ? (
                  <CloseIcon
                    style={{
                      color: softerColor
                    }}
                  />
                ) : (
                  <MessageIcon
                    style={{
                      color: softerColor
                    }}
                  />
                )}
              </IconContainer>
            </>
          )}
        </>
      ) : (
        <Text>
          {response.text}
          <QuoteIconContainer>
            <QuoteIcon
              style={{
                color: softColor
              }}
            />
          </QuoteIconContainer>
        </Text>
      )}
    </Component>
  );
}

export default StudyCard;

const Component = styled(motion.div)`
  flex-shrink: 0;
  position: relative;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 4;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  mask-image: url("/clip.svg");
  mask-size: cover;
  mask-position: center center;
  mask-repeat: no-repeat;

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`;

const Pop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1.75rem;

  p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    color: hsl(0 0% 10%);
  }
`;

const Text = styled(motion.p)`
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  color: black !important;
`;

const QuoteIconContainer = styled(motion.span)`
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  transform: translate(35%, -100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const QuoteIcon = styled(QuoteAltLeft)`
  width: 100%;
  height: 100%;
  display: block;
  color: black;
`;

const IconContainer = styled(motion.div)`
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const MessageIcon = styled(ChatSquareTextFill)`
  width: 100%;
  height: 100%;
  display: block;
  color: black;
`;

const CloseIcon = styled(Close)`
  width: 100%;
  height: 100%;
  display: block;
  color: black;
`;
