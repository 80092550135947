import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { media } from "@styles";

interface ButtonProps {
  /** What do you want the button to say? */
  children: any;
  /** Framer-Motion animation props */
  whileHover?: any;
  /** Framer-Motion animation props */
  whileTap?: any;
  onClick?: (event: SyntheticEvent) => void;
  /** Must specify one of the Sympler brand colors */
  color?: "blue" | "orange" | "purple" | "pink" | "lightPurple" | undefined;
  /** What time of button is this? */
  type?: "button" | "reset" | "submit" | undefined;
}

function CallToAction({
  children,
  whileHover,
  whileTap,
  onClick,
  color = "pink",
  type
}: ButtonProps) {
  return (
    <Button
      whileHover={whileHover}
      whileTap={whileTap}
      role="button"
      onClick={onClick}
      color={color}
      type={type}
    >
      <p>{children}</p>
    </Button>
  );
}

const Button = styled(motion.button)`
  font-size: 1.75rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  background: ${(p) => {
    if (p.color === "pink") return p.theme.colors.brand.pink;
    if (p.color === "orange") return p.theme.colors.brand.orange;
    if (p.color === "blue") return p.theme.colors.brand.blue;
    if (p.color === "purple") return p.theme.colors.brand.purple;
    if (p.color === "lightPurple") return p.theme.colors.brand.lightPurple;
  }};
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  p {
    letter-spacing: 1px;
    color: #fff;
    font-weight: 200;
    font-size: clamp(1.9rem, 4vw, 1.9rem);
    font-family: "Open Sans", sans-serif;
  }
`;

CallToAction.defaultProps = {
  whileHover: { scale: 1.05 },
  whileTap: { y: -3 }
};

export default CallToAction;
